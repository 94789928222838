h2 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  @include pc {
    margin-bottom: 4rem;
  }
  @include sp {
    text-align: center;
    margin-bottom: 4rem;
    word-wrap: break-word;
    overflow-wrap:break-word;
  }
  span {
    font-size: 1.6rem;
    margin-left: 1em;
    &.jp {
      font-size: 2rem;
      margin-left: 0;
    }
  }
}

.title_text {
  font-size: 2rem;
  color: $color_blue;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.05em;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  margin-bottom: 3rem;
  font-feature-settings: "palt" 1;
}
