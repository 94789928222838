$break_point: 768px;
$break_point_wide: 1095px;

// 769px以上 タブレットPC共通
@mixin pc {
  @media (min-width: ($break_point + 1px)) {
    @content;
  }
}

// 768px以下 SP
@mixin sp {
  @media (max-width: ($break_point)) {
    @content;
  }
}

// 769px以上1095以下 タブレット
@mixin tablet {
  @media (min-width: ($break_point + 1px)) and (max-width: ($break_point_wide)) {
    @content;
  }
}

// 1096px以上
@mixin wide_pc {
  @media (min-width: ($break_point_wide + 1px)) {
    @content;
  }
}

$spaceClass: true !default;
$spacePadding: false !default;
$endValue: 10 !default;
$widthClass: true !default;
