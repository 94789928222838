// ページ上部へ戻るボタン
#pagetop {
  cursor: pointer;
  opacity: 0;
  z-index: 100;

  @include wide_pc {
    position: fixed;
    right: 2rem;
    bottom: 30rem;
  }
  @include tablet {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
  @include sp {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
  img {
    width: 15.4rem;
    height: auto;
  }
  &.fadein {
    opacity: 1;
    transition: opacity 1s;
  }
}

// フェードイン効果
.fade-effect {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s, transform 1s ease-out;
  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  &.slide_left {
    opacity: 0;
    transform: translateY(0);
    transform: translateX(100px);
    transition: opacity 1s, transform 1s ease-out;

    &.visible {
      opacity: 1;
      transform: translateY(0);
      transform: translateX(0);
    }
  }
  &.slide_right {
    opacity: 0;
    transform: translateY(0);
    transform: translateX(-100px);
    transition: opacity 1s, transform 1s ease-out;

    &.visible {
      opacity: 1;
      transform: translateY(0);
      transform: translateX(0);
    }
  }
}

#open_nav {
  @include pc {
    display: none;
  }
  @include sp {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    background-color: $color_primary;
    z-index: 200;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    // padding: 1.3rem;
    cursor: pointer;
    & > span {
      background-color: $color_black1;
      width: 2.6rem;
      height: 0.2rem;
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 0.6rem;
      transition: transform .3s;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.open {
  #open_nav {
    @include sp {
      & > span {
        &:nth-child(1) {
          transition-delay: 25ms;
          transform: translateY(0.8rem) rotate(135deg);
        }
        &:nth-child(2) {
          transition-delay: 0s;
          transform: translateX(-1rem) scaleX(0);

        }
        &:nth-child(3) {
          transition-delay: 50ms;
          transform: translateY(-0.8rem) rotate(-135deg);
        }
      }
    }
  }
}


.inner {
  @include pc {
    max-width: 120rem;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-right: auto;
    margin-left: auto;
  }
  @include sp {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  &.footer {
    @include pc {
      max-width: 106rem;
    }
  }
}

.site_description {
  @include pc {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
  @include sp {
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }
}

.btn {
  display: inline-block;
  width: 24rem;
  height: 5rem;
  line-height: 4.8rem;
  border: solid 1px $color_black2;
  border-radius: 2.5rem;
  font-size: 1.7rem;
  text-align: center;
  @include pc {
    transition: all .3s;
  }
  &:hover {
    background-color: $color_hover;
  }
  &.btn_footer {
    &:hover {
      background-color: $color_white;
    }
  }
  &.small {
    @include wide_pc {
      width: 20rem;
    }
    @include tablet {
      width: 23.5%;
      font-size: 1.5rem;
    }
    @include sp {
      width: 100%;
      height: 4rem;
      line-height: 3.8rem;
      border: solid 1px $color_black2;
      border-radius: 2rem;
      font-size: 1.2rem;
    }
  }
}

.container {
  position: relative;
  flex: 1;
}

main {
  padding-left: 2rem;
  padding-right: 2rem;
  @include pc {
    max-width: 102.5rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 12.5rem;
  }
  @include sp {
    margin-bottom: 26rem;
  }
}

.spacer_large {
  @include pc {
    padding-top: 9rem;
  }
  @include sp {
    padding-top: 7rem;
  }
}

section {
  @extend .spacer_large;
}

// 外部サイトへのリンク
.link_external {
  font-size: 1.5rem;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-bottom: 0.5em;
  &::after {
    content: '';
    display: inline-block;
    background-image: url(../images/module/icon_external.png);
    background-size: contain;
    width: 1.4rem;
    height: 1.6rem;
    vertical-align: middle;
    margin-left: 0.5em;
  }
}
a:hover {
  .link_external {
    text-decoration: none;
  }
}

.breadcrumb {
  @extend .inner;
  margin-top: 2.5rem;
  ul {
    @extend .flex;
    align-items: center;
    li {
      font-size: 1.3rem;
      letter-spacing: 0.05em;

      margin-right: 0.5em;
      &:first-child::before {
        content: '';
        display: inline-block;
        background-image: url(../images/module/icon_home.svg);
        background-size: contain;
        width: 2rem;
        height: 2rem;
        vertical-align: bottom;
        margin-right: 0.75em;
      }
      & + li::before {
        content: '/';
        display: inline-block;
        margin-right: 0.5em;
      }
      a {
        text-decoration: underline;
        text-decoration-skip-ink: none;
      }
    }
  }

}

main > section:first-child {
  padding-top: 3.5rem;
}
