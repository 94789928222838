// _header.scss

header {
  @include pc {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
  @include sp {
     padding-top: 1.6rem;
     padding-bottom: 2rem;
  }


  .flex_block {
    @extend .flex;
    @include pc {
      justify-content: space-between;
      align-items: flex-end;
    }
    @include sp {
      flex-direction: column;
    }
  }

  .site_name {
    @include pc {
      margin-bottom: 2rem;
      margin-right: 2em;
    }
    img {
      @include pc {
        width: 21.7rem;
        height: auto;
      }
      @include sp {
        width: 20.5rem;
        height: auto;
      }
    }
    a {
      display: block;
      @include pc {
        transition: opacity .3s;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }

  .global_nav {
    @include pc {
      display: flex;
      column-gap: 2em;
      justify-content: space-between;
      align-items: center;
      font-size: 1.7rem;
      margin-bottom: 2rem;
    }
    @include tablet {
      font-size: 1.7rem;
      column-gap: 1.4em;
    }
    @include sp {
      font-size: 1.4rem;
      position: fixed;
      inset: 0 -100% 0 100%;
      z-index: 100;
      background-color: $color_primary;
      transition: transform 0.4s;
      padding-top: 6rem;
    }

    li {
      a {
        display: block;
        text-align: center;
        user-select: none;
        @include pc {
          transition: opacity .3s;
        }
        @include sp {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
      span {
        display: block;
        color: $color_yellow;
         @include pc {
          font-size: 1.3rem;
         }
         @include tablet {
          font-size: 1.2rem;
        }
         @include sp {
          font-size: 1.2rem;
         }
      }
    }
  }


}
.open .global_nav {
  transform: translateX(-100%);
}
