@charset "UTF-8";
/* 基本 */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap");
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.5rem;
  font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #000000;
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body.open {
  position: fixed;
  overflow: hidden;
  width: 100%;
}

p {
  line-height: 1.5;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.075em;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.flex, #photo_memory ul li a, #production_award ul, #information .link_button, #information ul, footer .footer_nav, .new_article a, .pagination, header .flex_block, .breadcrumb ul {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .pc_visible {
    display: none;
  }
}

@media (min-width: 769px) {
  .sp_visible {
    display: none;
  }
}

img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

#pagetop {
  cursor: pointer;
  opacity: 0;
  z-index: 100;
}
@media (min-width: 1096px) {
  #pagetop {
    position: fixed;
    right: 2rem;
    bottom: 30rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  #pagetop {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
}
@media (max-width: 768px) {
  #pagetop {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
}
#pagetop img {
  width: 15.4rem;
  height: auto;
}
#pagetop.fadein {
  opacity: 1;
  transition: opacity 1s;
}

.fade-effect {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s, transform 1s ease-out;
}
.fade-effect.visible {
  opacity: 1;
  transform: translateY(0);
}
.fade-effect.slide_left {
  opacity: 0;
  transform: translateY(0);
  transform: translateX(100px);
  transition: opacity 1s, transform 1s ease-out;
}
.fade-effect.slide_left.visible {
  opacity: 1;
  transform: translateY(0);
  transform: translateX(0);
}
.fade-effect.slide_right {
  opacity: 0;
  transform: translateY(0);
  transform: translateX(-100px);
  transition: opacity 1s, transform 1s ease-out;
}
.fade-effect.slide_right.visible {
  opacity: 1;
  transform: translateY(0);
  transform: translateX(0);
}

@media (min-width: 769px) {
  #open_nav {
    display: none;
  }
}
@media (max-width: 768px) {
  #open_nav {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    background-color: #fef5d4;
    z-index: 200;
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
    cursor: pointer;
  }
  #open_nav > span {
    background-color: #333333;
    width: 2.6rem;
    height: 0.2rem;
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 0.6rem;
    transition: transform 0.3s;
  }
  #open_nav > span:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .open #open_nav > span:nth-child(1) {
    transition-delay: 25ms;
    transform: translateY(0.8rem) rotate(135deg);
  }
  .open #open_nav > span:nth-child(2) {
    transition-delay: 0s;
    transform: translateX(-1rem) scaleX(0);
  }
  .open #open_nav > span:nth-child(3) {
    transition-delay: 50ms;
    transform: translateY(-0.8rem) rotate(-135deg);
  }
}

@media (min-width: 769px) {
  .inner, .breadcrumb {
    max-width: 120rem;
    padding-right: 2rem;
    padding-left: 2rem;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .inner, .breadcrumb {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 769px) {
  .inner.footer, .footer.breadcrumb {
    max-width: 106rem;
  }
}

@media (min-width: 769px) {
  .site_description {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .site_description {
    font-size: 1.4rem;
    margin-bottom: 1.6rem;
  }
}

.btn {
  display: inline-block;
  width: 24rem;
  height: 5rem;
  line-height: 4.8rem;
  border: solid 1px #231815;
  border-radius: 2.5rem;
  font-size: 1.7rem;
  text-align: center;
}
@media (min-width: 769px) {
  .btn {
    transition: all 0.3s;
  }
}
.btn:hover {
  background-color: #f7eecf;
}
.btn.btn_footer:hover {
  background-color: #ffffff;
}
@media (min-width: 1096px) {
  .btn.small {
    width: 20rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  .btn.small {
    width: 23.5%;
    font-size: 1.5rem;
  }
}
@media (max-width: 768px) {
  .btn.small {
    width: 100%;
    height: 4rem;
    line-height: 3.8rem;
    border: solid 1px #231815;
    border-radius: 2rem;
    font-size: 1.2rem;
  }
}

.container {
  position: relative;
  flex: 1;
}

main {
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 769px) {
  main {
    max-width: 102.5rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 12.5rem;
  }
}
@media (max-width: 768px) {
  main {
    margin-bottom: 26rem;
  }
}

@media (min-width: 769px) {
  .spacer_large, section {
    padding-top: 9rem;
  }
}
@media (max-width: 768px) {
  .spacer_large, section {
    padding-top: 7rem;
  }
}

.link_external {
  font-size: 1.5rem;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  margin-bottom: 0.5em;
}
.link_external::after {
  content: "";
  display: inline-block;
  background-image: url(../images/module/icon_external.png);
  background-size: contain;
  width: 1.4rem;
  height: 1.6rem;
  vertical-align: middle;
  margin-left: 0.5em;
}

a:hover .link_external {
  text-decoration: none;
}

.breadcrumb {
  margin-top: 2.5rem;
}
.breadcrumb ul {
  align-items: center;
}
.breadcrumb ul li {
  font-size: 1.3rem;
  letter-spacing: 0.05em;
  margin-right: 0.5em;
}
.breadcrumb ul li:first-child::before {
  content: "";
  display: inline-block;
  background-image: url(../images/module/icon_home.svg);
  background-size: contain;
  width: 2rem;
  height: 2rem;
  vertical-align: bottom;
  margin-right: 0.75em;
}
.breadcrumb ul li + li::before {
  content: "/";
  display: inline-block;
  margin-right: 0.5em;
}
.breadcrumb ul li a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

main > section:first-child {
  padding-top: 3.5rem;
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media (min-width: 769px) {
  h2 {
    margin-bottom: 4rem;
  }
}
@media (max-width: 768px) {
  h2 {
    text-align: center;
    margin-bottom: 4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
h2 span {
  font-size: 1.6rem;
  margin-left: 1em;
}
h2 span.jp {
  font-size: 2rem;
  margin-left: 0;
}

.title_text {
  font-size: 2rem;
  color: #61b7d9;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.05em;
}

h3 {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.075em;
  margin-bottom: 3rem;
  font-feature-settings: "palt" 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (min-width: 769px) {
  header {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  header {
    padding-top: 1.6rem;
    padding-bottom: 2rem;
  }
}
@media (min-width: 769px) {
  header .flex_block {
    justify-content: space-between;
    align-items: flex-end;
  }
}
@media (max-width: 768px) {
  header .flex_block {
    flex-direction: column;
  }
}
@media (min-width: 769px) {
  header .site_name {
    margin-bottom: 2rem;
    margin-right: 2em;
  }
}
@media (min-width: 769px) {
  header .site_name img {
    width: 21.7rem;
    height: auto;
  }
}
@media (max-width: 768px) {
  header .site_name img {
    width: 20.5rem;
    height: auto;
  }
}
header .site_name a {
  display: block;
}
@media (min-width: 769px) {
  header .site_name a {
    transition: opacity 0.3s;
  }
}
header .site_name a:hover {
  opacity: 0.6;
}
@media (min-width: 769px) {
  header .global_nav {
    display: flex;
    column-gap: 2em;
    justify-content: space-between;
    align-items: center;
    font-size: 1.7rem;
    margin-bottom: 2rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  header .global_nav {
    font-size: 1.7rem;
    column-gap: 1.4em;
  }
}
@media (max-width: 768px) {
  header .global_nav {
    font-size: 1.4rem;
    position: fixed;
    inset: 0 -100% 0 100%;
    z-index: 100;
    background-color: #fef5d4;
    transition: transform 0.4s;
    padding-top: 6rem;
  }
}
header .global_nav li a {
  display: block;
  text-align: center;
  user-select: none;
}
@media (min-width: 769px) {
  header .global_nav li a {
    transition: opacity 0.3s;
  }
}
@media (max-width: 768px) {
  header .global_nav li a {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}
header .global_nav li a:hover {
  opacity: 0.6;
}
header .global_nav li span {
  display: block;
  color: #f4d05d;
}
@media (min-width: 769px) {
  header .global_nav li span {
    font-size: 1.3rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  header .global_nav li span {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  header .global_nav li span {
    font-size: 1.2rem;
  }
}

.open .global_nav {
  transform: translateX(-100%);
}

ul.topics_list {
  margin-bottom: 2.5rem;
}
ul.topics_list.front li:last-child {
  border-bottom: dashed 0px #c2c2c2;
}
ul.topics_list li {
  border-bottom: dashed 1px #c2c2c2;
}
@media (min-width: 769px) {
  ul.topics_list li a {
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
@media (max-width: 768px) {
  ul.topics_list li a {
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
ul.topics_list li a:hover .subject {
  text-decoration: none;
}
ul.topics_list li:first-child a {
  padding-top: 0;
}
@media (min-width: 769px) {
  ul.topics_list .category_block {
    width: 17rem;
  }
}
ul.topics_list .category {
  font-size: 1.3rem;
  color: #ffffff;
  width: 15rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-radius: 0.3rem;
}
ul.topics_list .category.magazine {
  background-color: #f0b0b2;
}
ul.topics_list .category.room {
  background-color: #61b7d9;
}
ul.topics_list .category.news {
  background-color: #f4d05d;
}
@media (max-width: 768px) {
  ul.topics_list .category {
    margin-bottom: 1rem;
  }
}
@media (min-width: 769px) {
  ul.topics_list .post_block {
    width: calc(100% - 17rem);
  }
}
ul.topics_list .post_block .datetime {
  font-size: 1.3rem;
  letter-spacing: 0.05em;
}
ul.topics_list .post_block .subject {
  font-size: 1.5rem;
  letter-spacing: 0.05em;
  display: inline-block;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
ul.topics_list .post_block .subject span {
  display: inline-block;
  padding-left: 1em;
  color: #ff7ba2;
  text-decoration: none;
}

.pagination {
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 5rem;
}
.pagination .prev,
.pagination .next {
  display: inline-block;
}
.pagination .prev a,
.pagination .next a {
  display: block;
}
.pagination .prev a img,
.pagination .next a img {
  width: 3rem;
  height: 3rem;
}
.pagination .prev {
  transform: scale(-1, 1);
}
.pagination ul {
  display: flex;
  padding-left: 2em;
  padding-right: 2em;
}
.pagination ul li a {
  display: block;
  text-decoration: underline;
  padding-right: 0.75em;
  padding-left: 0.75em;
}
.pagination ul li.active a {
  color: #ff7ba2;
  text-decoration: none;
}
.pagination ul li.sp_visible {
  margin-left: 0.5em;
}

.article_title {
  padding-left: 2em;
  padding-right: 2em;
}

.new_article {
  margin-bottom: 6rem;
}
@media (max-width: 768px) {
  .new_article {
    margin-bottom: 5rem;
  }
}
.new_article a {
  box-sizing: border-box;
}
.new_article a .thumbnail {
  border: solid 1px #c2c2c2;
}
@media (min-width: 769px) {
  .new_article a .thumbnail {
    width: 21rem;
    margin-right: 4.5rem;
  }
}
@media (max-width: 768px) {
  .new_article a .thumbnail {
    width: 18.2rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 769px) {
  .new_article a .block {
    width: calc(100% - 25.5rem);
  }
}
.new_article a .block .title_text {
  margin-bottom: 1em;
  display: inline-block;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.new_article a .block .title_text .new {
  font-size: 1.5rem;
  color: #ff7ba2;
  margin-left: 1em;
  text-decoration: none;
  display: inline-block;
}
.new_article a .block p {
  margin-bottom: 5rem;
}
@media (max-width: 768px) {
  .new_article a .block p {
    margin-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  .new_article a .link_sp_center {
    display: flex;
    justify-content: center;
  }
}
.new_article a .btn {
  justify-content: center;
}
.new_article a:hover .thumbnail {
  opacity: 0.8;
}
.new_article a:hover .title_text {
  text-decoration: none;
}

article {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  border: solid 1px #c2c2c2;
  margin-top: 4rem;
}

.title_text a {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.title_text a:hover {
  text-decoration: none;
}

@media (min-width: 769px) {
  .kv {
    height: 46rem;
  }
}
@media (min-width: 769px) {
  .kv .inner, .kv .breadcrumb {
    position: relative;
  }
}
@media (max-width: 768px) {
  .kv .inner, .kv .breadcrumb {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 769px) {
  .kv .inner .kv_wrap, .kv .breadcrumb .kv_wrap {
    position: absolute;
    top: 0;
    right: 3rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  .kv .inner .kv_wrap, .kv .breadcrumb .kv_wrap {
    right: 0;
  }
}
@media (min-width: 769px) {
  .kv .block {
    position: relative;
    width: 84rem;
  }
}
@media (min-width: 769px) {
  .kv .block .kv_img {
    position: relative;
    width: 42.8rem;
  }
}
@media (min-width: 769px) {
  .kv .block .kv_img img {
    width: 31rem;
    height: auto;
  }
}
@media (max-width: 768px) {
  .kv .block .kv_img img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 769px) {
  .kv .block .kv_img::after {
    content: "";
    display: block;
    width: 33rem;
    height: 33.7rem;
    background-color: #fef5d4;
    position: absolute;
    top: 12.3rem;
    left: 9.8rem;
    z-index: -10;
  }
}
.kv .catch {
  letter-spacing: 0.05em;
}
.kv .catch::before {
  content: "";
  display: inline-block;
  background-size: contain;
  vertical-align: super;
  margin-right: 1em;
}
@media (min-width: 769px) {
  .kv .catch::before {
    background-image: url(../images/module/border_large.svg);
    width: 4rem;
    height: 0.1rem;
  }
}
@media (max-width: 768px) {
  .kv .catch::before {
    background-image: url(../images/module/border.svg);
    width: 2.7rem;
    height: 0.1rem;
  }
}
@media (min-width: 769px) {
  .kv .catch {
    font-size: 2.6rem;
    position: absolute;
    top: 45%;
    right: 2rem;
  }
}
@media (max-width: 768px) {
  .kv .catch {
    font-size: 1.9rem;
    background-color: #fef5d4;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 769px) {
  .kv.front {
    height: 80rem;
  }
}
@media (min-width: 769px) {
  .kv.front .inner .kv_wrap, .kv.front .breadcrumb .kv_wrap {
    position: absolute;
    top: 0;
    right: 3rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  .kv.front .inner .kv_wrap, .kv.front .breadcrumb .kv_wrap {
    right: 0;
  }
}
@media (min-width: 769px) {
  .kv.front .block {
    position: relative;
    width: 83.5rem;
  }
}
@media (min-width: 769px) {
  .kv.front .block .kv_img {
    position: relative;
    width: 71rem;
  }
}
@media (min-width: 769px) {
  .kv.front .block .kv_img img {
    width: 48rem;
    height: auto;
  }
}
@media (min-width: 769px) {
  .kv.front .block .kv_img::after {
    content: "";
    display: block;
    width: 57rem;
    height: 59rem;
    background-color: #fef5d4;
    position: absolute;
    top: 21rem;
    left: 14rem;
    z-index: -10;
  }
}

footer {
  background-color: #fef5d4;
  color: #231815;
}
@media (min-width: 769px) {
  footer {
    padding-top: 4.5rem;
    padding-bottom: 1rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  footer {
    padding-top: 4.5rem;
    padding-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  footer {
    padding-top: 5rem;
    padding-bottom: 6.5rem;
  }
}
@media (max-width: 768px) {
  footer .footer {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (min-width: 769px) {
  footer .site {
    display: flex;
    align-items: flex-end;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  footer .site {
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  footer .site_name {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 769px) {
  footer .site_name img {
    width: 21.8rem;
    height: auto;
  }
}
@media (max-width: 768px) {
  footer .site_name img {
    width: 21.9rem;
    height: auto;
  }
}
footer .site_name a {
  display: block;
}
@media (min-width: 769px) {
  footer .site_name a {
    transition: opacity 0.3s;
  }
  footer .site_name a:hover {
    opacity: 0.7;
  }
}
footer .mission {
  font-size: 1.8rem;
  line-height: 1;
}
footer .mission::before {
  content: "";
  display: inline-block;
  background-image: url(../images/module/border.svg);
  background-size: contain;
  width: 2.7rem;
  height: 0.1rem;
  vertical-align: super;
  margin-right: 1em;
}
@media (min-width: 769px) {
  footer .mission {
    padding-bottom: 8px;
    margin-left: 1em;
  }
}
@media (max-width: 768px) {
  footer .mission {
    margin-bottom: 5rem;
  }
}
@media (min-width: 769px) {
  footer .contact_link {
    text-align: right;
    margin-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  footer .contact_link {
    text-align: center;
    margin-bottom: 2rem;
  }
}
footer .footer_nav {
  font-size: 1.4rem;
}
@media (min-width: 769px) {
  footer .footer_nav {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  footer .footer_nav {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  footer .footer_nav {
    border-top: dashed 1px #c2c2c2;
    border-bottom: dashed 1px #c2c2c2;
    margin-bottom: 5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}
@media (min-width: 769px) {
  footer .footer_nav li + li {
    border-left: solid 1px #231815;
  }
}
@media (max-width: 768px) {
  footer .footer_nav li {
    width: 50%;
  }
  footer .footer_nav li:nth-child(1) {
    order: 1;
  }
  footer .footer_nav li:nth-child(2) {
    order: 3;
  }
  footer .footer_nav li:nth-child(3) {
    order: 5;
  }
  footer .footer_nav li:nth-child(4) {
    order: 7;
  }
  footer .footer_nav li:nth-child(5) {
    order: 9;
  }
  footer .footer_nav li:nth-child(6) {
    order: 2;
  }
  footer .footer_nav li:nth-child(7) {
    order: 4;
  }
  footer .footer_nav li:nth-child(8) {
    order: 6;
  }
  footer .footer_nav li:nth-child(9) {
    order: 8;
  }
  footer .footer_nav li:nth-child(10) {
    order: 10;
  }
}
footer .footer_nav li a {
  display: block;
  line-height: 1;
}
@media (min-width: 769px) {
  footer .footer_nav li a {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }
  footer .footer_nav li a:hover {
    text-decoration: underline;
  }
}
@media (min-width: 769px) and (max-width: 1095px) {
  footer .footer_nav li a {
    padding-right: 1em;
    padding-left: 1em;
  }
}
@media (max-width: 768px) {
  footer .footer_nav li a {
    padding-top: 1em;
    padding-bottom: 1em;
  }
}
footer .copyright {
  text-align: center;
  font-size: 1.1rem;
}

#topics .link {
  font-size: 1.5rem;
}
@media (min-width: 769px) {
  #topics .link {
    text-align: right;
  }
}
@media (max-width: 768px) {
  #topics .link {
    text-align: center;
  }
}
#topics .link a::after {
  content: "";
  display: inline-block;
  background-image: url(../images/module/icon_arrow.svg);
  background-size: contain;
  width: 3rem;
  height: 3rem;
  vertical-align: middle;
  margin-left: 0.5em;
}
#topics .link:hover {
  opacity: 0.7;
}

@media (min-width: 769px) {
  #information .article {
    margin-bottom: 3rem;
  }
}
@media (max-width: 768px) {
  #information .article {
    margin-bottom: 5rem;
  }
}
@media (min-width: 769px) {
  #information ul {
    column-gap: 3.048%;
    row-gap: 3rem;
  }
}
@media (max-width: 768px) {
  #information ul {
    column-gap: 4%;
    row-gap: 3rem;
  }
}
@media (min-width: 769px) {
  #information ul li {
    width: 17.56%;
  }
}
@media (max-width: 768px) {
  #information ul li {
    width: 48%;
  }
}
#information ul .thumbnail {
  border: solid 0.1rem #9fa0a0;
  margin-bottom: 1em;
}
#information ul .title {
  font-size: 1.5rem;
  color: #61b7d9;
  margin-bottom: 0.5em;
}
#information ul .description {
  font-size: 1.5rem;
}
#information ul a .title {
  text-decoration: underline 1px #61b7d9;
}
#information ul a .description {
  text-decoration: underline 1px #231815;
}
#information ul a:hover .thumbnail {
  opacity: 0.7;
}
#information ul a:hover .title {
  text-decoration: none;
}
#information ul a:hover .description {
  text-decoration: none;
}
@media (min-width: 769px) {
  #information .link_button {
    justify-content: center;
    column-gap: 2%;
  }
}
@media (max-width: 768px) {
  #information .link_button {
    column-gap: 4%;
    row-gap: 3rem;
  }
}
@media (max-width: 768px) {
  #information .link_button a {
    width: 48%;
  }
}
@media (min-width: 769px) {
  #information .link_button .btn.small {
    width: 23.5%;
  }
}

#tv a {
  display: block;
}
#tv a dt {
  margin-bottom: 2rem;
}
#tv a:hover .link_title {
  text-decoration: none;
}
#tv a p {
  font-size: 1.4rem;
}

#production_award ul {
  row-gap: 3rem;
}
@media (min-width: 769px) {
  #production_award ul li {
    width: 50%;
  }
  #production_award ul li:nth-child(odd) {
    padding-right: 3rem;
  }
  #production_award ul li:nth-child(even) {
    padding-left: 3rem;
    border-left: dashed 1px #c2c2c2;
  }
}
#production_award ul li a {
  display: flex;
  flex-wrap: wrap;
}
#production_award ul li a:hover .thumbnail {
  opacity: 0.7;
}
#production_award ul li .thumbnail {
  width: 12rem;
  margin-right: 3rem;
}
#production_award ul li .block {
  width: calc(100% - 15rem);
}
#production_award ul li .block .title_text {
  margin-bottom: 1em;
}

#photo_memory ul {
  margin-bottom: 4rem;
}
#photo_memory ul li {
  margin-bottom: 3rem;
}
@media (min-width: 769px) {
  #photo_memory ul li a .thumbnail {
    width: 42rem;
    margin-right: 4rem;
    transition: opacity 0.3s;
  }
}
@media (max-width: 768px) {
  #photo_memory ul li a .thumbnail {
    margin-bottom: 2.5rem;
  }
}
@media (min-width: 769px) {
  #photo_memory ul li a .block {
    width: calc(100% - 46rem);
  }
}
#photo_memory ul li a .block .title_text {
  margin-bottom: 1em;
}
#photo_memory ul li a .block p {
  margin-bottom: 1em;
}
#photo_memory ul li a:hover .thumbnail {
  opacity: 0.7;
}