.new_article {
  margin-bottom: 6rem;
  @include sp {
    margin-bottom: 5rem;
  }

  a {
    @extend .flex;
    box-sizing: border-box;
    .thumbnail {
      border: solid 1px $color_gray;
      @include pc {
        width: 21rem;
        margin-right: 4.5rem;
      }
      @include sp {
        width: 18.2rem;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 2.5rem;
      }
    }
    .block {
      @include pc {
        width: calc(100% - 25.5rem);
      }
      .title_text {
        margin-bottom: 1em;
        display: inline-block;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        .new {
          font-size: 1.5rem;
          color: $color_pink2;
          margin-left: 1em;
          text-decoration: none;
          display: inline-block;
        }
      }
      p {
        margin-bottom: 5rem;
        @include sp {
          margin-bottom: 3rem;
        }
      }
    }
    .link_sp_center {
      @include sp {
        display: flex;
        justify-content: center;
      }
    }
    .btn {
      justify-content: center;
    }
    &:hover {
      .thumbnail {
        opacity: 0.8;
      }
      .title_text {
        text-decoration: none;
      }
    }
  }
}

article {
  max-width: 80rem;
  margin-right: auto;
  margin-left: auto;
  border: solid 1px $color_gray;
  margin-top: 4rem;
}

.title_text {
  a {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    &:hover {
      text-decoration: none;
    }
  }
}
