// _index.scss
#topics {

  .link {
    font-size: 1.5rem;
    @include pc {
      text-align: right;
    }
    @include sp {
      text-align: center;
    }
    a::after {
      content: '';
      display: inline-block;
      background-image: url(../images/module/icon_arrow.svg);
      background-size: contain;
      width: 3rem;
      height: 3rem;
      vertical-align: middle;
      margin-left: 0.5em;
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

#information {
  .article {
    @include pc {
      margin-bottom: 3rem;
    }
    @include sp {
      margin-bottom: 5rem;
    }
  }
  ul {
    @extend .flex;
    @include pc {
      column-gap: 3.048%;
      row-gap: 3rem;
    }
    @include sp {
      column-gap: 4%;
      row-gap: 3rem;
    }
    li {
      @include pc {
        width: 17.56%;
      }
      @include sp {
        width: 48%;
      }
    }
    .thumbnail {
      border: solid 0.1rem #9fa0a0;
      margin-bottom: 1em;
    }
    .title {
      font-size: 1.5rem;
      color: $color_blue;
      margin-bottom: 0.5em;
    }
    .description {
      font-size: 1.5rem;
    }
    a {
      .title {
        text-decoration: underline 1px $color_blue;
      }
      .description {
        text-decoration: underline 1px $color_black2;
      }
      &:hover {
        .thumbnail {
          opacity: 0.7;
        }
        .title {
          text-decoration: none;
        }
        .description {
          text-decoration: none;
        }
      }
    }
  }
  .link_button {
    @extend .flex;
    @include pc {
      justify-content: center;
      // column-gap: 3rem;
      column-gap:2%;
    }
    @include sp {
      column-gap: 4%;
      row-gap: 3rem;
    }
    a {
      @include sp {
        width: 48%;
      }
    }
    .btn.small {
      @include pc {
        width: 23.5%;
      }
    }
  }
}

#tv {
  a {
    display: block;
    dt {
      margin-bottom: 2rem;
    }

    &:hover {
      .link_title {
        text-decoration: none;
      }
    }

    p {
      font-size: 1.4rem;
    }
  }
}

#production_award {
  ul {
    @extend .flex;
    row-gap: 3rem;

    li {
      @include pc {
        width: 50%;
        &:nth-child(odd) {
          padding-right: 3rem;
        }
        &:nth-child(even) {
         padding-left: 3rem;
         border-left: dashed 1px $color_gray;
        }
      }

      a {
        display: flex;
        flex-wrap: wrap;
        &:hover {
          .thumbnail {
            opacity: 0.7;
          }
        }
      }

      .thumbnail {
        width: 12rem;
        margin-right: 3rem;
      }

      .block {
        width: calc(100% - 15rem);
        .title_text {
          margin-bottom: 1em;
        }
      }
    }
  }
}

#photo_memory {
  ul {
    margin-bottom: 4rem;
    li {
      margin-bottom: 3rem;
      a {
        @extend .flex;
        .thumbnail {
          @include pc {
            width: 42rem;
            margin-right: 4rem;
            transition: opacity .3s;
          }
          @include sp {
            margin-bottom: 2.5rem;
          }
        }
        .block {
          @include pc {
            width: calc(100% - 46rem);
          }
          .title_text {
            margin-bottom: 1em;
          }
          p {
            margin-bottom: 1em;
          }
        }
        &:hover {
          .thumbnail {
            opacity: 0.7;
          }
        }
      }
    }
  }
}
