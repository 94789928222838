// _footer.scss

footer {
  background-color: $color_primary;
  color: $color_black2;
  @include pc {
    padding-top: 4.5rem;
    padding-bottom: 1rem;
  }
  @include tablet {
    padding-top: 4.5rem;
    padding-bottom: 2rem;
  }
  @include sp {
    padding-top: 5rem;
    padding-bottom: 6.5rem;
  }

  .footer {
    @include sp {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .site {
    @include pc {
      display: flex;
      align-items: flex-end;
    }
    @include tablet {
      margin-bottom: 2rem;
    }
  }
  .site_name {
    @include sp {
      margin-bottom: 2.5rem;
    }
    img {
      @include pc {
        width: 21.8rem;
        height: auto;
      }
      @include sp {
        width: 21.9rem;
        height: auto;
      }
    }
    a {
      display: block;
      @include pc {
        transition: opacity .3s;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .mission {
    font-size: 1.8rem;
    line-height: 1;
    &::before {
      content: '';
      display: inline-block;
      background-image: url(../images/module/border.svg);
      background-size: contain;
      width: 2.7rem;
      height: 0.1rem;
      vertical-align: super;
      margin-right: 1em;
    }
    @include pc {
      padding-bottom: 8px;
      margin-left: 1em;
    }
    @include sp {
      margin-bottom: 5rem;
    }
  }
  .contact_link {
    @include pc {
      text-align: right;
      margin-bottom: 3rem;
    }
    @include sp {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .footer_nav {
    font-size: 1.4rem;
    @extend .flex;
    @include pc {
      justify-content: center;
      margin-bottom: 1rem;
    }
    @include tablet {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
    @include sp {
      border-top: dashed 1px $color_gray;
      border-bottom: dashed 1px $color_gray;
      margin-bottom: 5rem;
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
    }
    li {
      @include pc {
        & + li {
          border-left: solid 1px $color_black2 ;
        }

      }
      @include sp {
        width: 50%;
        &:nth-child(1) { order: 1;}
        &:nth-child(2) { order: 3;}
        &:nth-child(3) { order: 5;}
        &:nth-child(4) { order: 7;}
        &:nth-child(5) { order: 9;}
        &:nth-child(6) { order: 2;}
        &:nth-child(7) { order: 4;}
        &:nth-child(8) { order: 6;}
        &:nth-child(9) { order: 8;}
        &:nth-child(10) { order: 10;}
      }
      a {
        display: block;
        line-height: 1;
        @include pc {
          padding-right: 1.5em;
          padding-left: 1.5em;
          &:hover {
            text-decoration: underline;
          }
        }
        @include tablet {
          padding-right: 1em;
          padding-left: 1em;
        }
        @include sp {
          padding-top: 1em;
          padding-bottom: 1em;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    font-size: 1.1rem;
  }
}
