/* 基本 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&family=Roboto:wght@300;400;500;700&display=swap');

html {
  font-size: 62.5%;
}
body {
  margin: 0;
  font-size: 1.5rem;
  font-family: '游明朝', YuMincho, 'Hiragino Mincho ProN W3',
    'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝',
    'ＭＳ 明朝', serif;
  color: $color_pure_black;
  animation: fadeIn 2s ease 0s 1 normal;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &.open {
    position: fixed;
    overflow: hidden;
    width: 100%;
  }
}
p {
  line-height: 1.5;
  font-feature-settings: "palt" 1;
  letter-spacing: 0.075em;
}

a {
  text-decoration: none;
  color: inherit;
}
ul {
  list-style: none;
}
.flex {
  display: flex;
  flex-wrap: wrap;
}

.pc_visible {
  @include sp {
    display: none;
  }
}

.sp_visible {
  @include pc {
    display: none;
  }
}
img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}
