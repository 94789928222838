ul.topics_list {
  &.front {
    li:last-child {
      border-bottom: dashed 0px $color_gray;
    }
  }
  margin-bottom: 2.5rem;
  li {
    border-bottom: dashed 1px $color_gray;
    a {
      @include pc {
        padding-top: 2rem;
        padding-bottom: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @include sp {
        display: block;
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
      &:hover {
        .subject {
          text-decoration: none;
        }
      }
    }
    &:first-child {
      a {
        padding-top: 0;
      }
    }
  }
  .category_block {
    @include pc {
      width: 17rem;
    }
  }
  .category {
    font-size: 1.3rem;
    color: $color_white;
    width: 15rem;
    height: 2.5rem;
    line-height: 2.5rem;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-radius: 0.3rem;
    &.magazine {
      background-color: $color_pink1;
    }
    &.room {
      background-color: $color_blue;
    }
    &.news {
      background-color: $color_yellow;
    }

    @include sp {
      margin-bottom: 1rem;
    }
  }

  .post_block {
    @include pc {
      width: calc(100% - 17rem);
    }
    .datetime {
      font-size: 1.3rem;
      letter-spacing: 0.05em;
    }
    .subject {
      font-size: 1.5rem;
      letter-spacing: 0.05em;
      display: inline-block;
      text-decoration: underline;
      text-decoration-skip-ink: none;
      span {
        display: inline-block;
        padding-left: 1em;
        color: $color_pink2;
        text-decoration: none;
      }
    }
  }
}

.pagination {
  @extend .flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 5rem;
  .prev,
  .next {
    display: inline-block;
    a {
      display: block;
      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  .prev {
    transform: scale(-1, 1);
  }
  ul {
    // @extend .flex;
    display: flex;
    // column-gap: 1.75em;
    // justify-content: center;
    padding-left: 2em;
    padding-right: 2em;
    @include pc {
      // width: 38rem;
    }
    @include sp {

    }
    li {
      a {
        display: block;
        text-decoration: underline;
        padding-right: 0.75em;
        padding-left: 0.75em;
      }
      &.active a {
        color: $color_pink2;
        text-decoration: none;
      }
      &.sp_visible {
        margin-left: 0.5em;
      }
    }
  }
}
.article_title {
  padding-left: 2em;
  padding-right: 2em;
}
