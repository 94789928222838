$color_primary: #fef5d4;
$color_blue: #61b7d9;
$color_green: #c9decf;
$color_pink1: #f0b0b2;
$color_pink2: #ff7ba2;
$color_purple: #dadafc;
$color_yellow: #f4d05d;
$color_white: #ffffff;
$color_black1: #333333;
$color_black2: #231815;
$color_pure_black: #000000;
$color_gray: #c2c2c2;
$color_hover: #f7eecf;
