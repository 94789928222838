.kv {
  @include pc {
    height: 46rem;
  }
  .inner {
    @include pc {
      position: relative;
    }
    @include sp {
      padding-right: 0;
      padding-left: 0;
    }
    .kv_wrap {
      @include pc {
        position: absolute;
        top:0;
        right: 3rem;
      }
      @include tablet {
        right: 0;
      }
    }
  }

  .block {
    @include pc {
      position: relative;
      width: 84rem;
    }
    .kv_img {
      @include pc {
        position: relative;
        width: 42.8rem;
      }
      img {
        @include pc {
          width: 31rem;
          height: auto;
        }
        @include sp {
          width: 100%;
          height: auto;
        }
      }
      &::after {
        @include pc {
          content: '';
          display: block;
          width: 33rem;
          height: 33.7rem;
          background-color: $color_primary;
          position: absolute;
          top: 12.3rem;
          left: 9.8rem;
          z-index: -10;
        }
      }
    }
  }

  .catch {
    letter-spacing: 0.05em;
    &::before {
      content: '';
      display: inline-block;
      background-size: contain;
      vertical-align: super;
      margin-right: 1em;
      @include pc {
        background-image: url(../images/module/border_large.svg);
        width: 4rem;
        height: 0.1rem;
      }
      @include sp {
        background-image: url(../images/module/border.svg);
        width: 2.7rem;
        height: 0.1rem;
      }
    }
    @include pc {
      font-size: 2.6rem;
      position: absolute;
      top: 45%;
      right: 2rem;

    }
    @include sp {
      font-size: 1.9rem;
      background-color: $color_primary;
      height: 8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.front {
    @include pc {
      height: 80rem;
    }
    .inner {
      // @include pc {
      //   position: relative;
      // }
      // @include sp {
      //   padding-right: 0;
      //   padding-left: 0;
      // }
      .kv_wrap {
        @include pc {
          position: absolute;
          top:0;
          right: 3rem;
        }
        @include tablet {
          right: 0;
        }
      }
    }

    .block {
      @include pc {
        position: relative;
        width: 83.5rem;
      }
      .kv_img {
        @include pc {
          position: relative;
          width: 71rem;
        }
        img {
          @include pc {
            width: 48rem;
            height: auto;
          }
          // @include sp {
          //   width: 100%;
          //   height: auto;
          // }
        }
        &::after {
          @include pc {
            content: '';
            display: block;
            width: 57rem;
            height: 59rem;
            background-color: $color_primary;
            position: absolute;
            top: 21rem;
            left: 14rem;
            z-index: -10;
          }
        }
      }
    }
  }
}
